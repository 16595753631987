<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="subcategoriaAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="px-2 py-2 pb-0">
        <b-row class="w-100">
          <b-col md="3" class="pl-0">
            <b-form-group label="Descripción" label-for="description" class="mr-2 w-100">
              <b-form-input v-model="keywordDesc" id="description" placeholder="Descripción" autocomplete="off"
                @input="searrchDataByFilter" />
            </b-form-group>
          </b-col>
          <b-col md="1" class="pl-0">
            <b-form-group label="SSOMA" label-for="ssomac" class="mr-2 w-100">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="SSOMACarray" label="text"
                input-id="ssomac" :reduce="(SSOMACarray) => SSOMACarray.value" v-model="ssomac" @input="searrchDataByFilter"
                placeholder="-" />
            </b-form-group>
          </b-col>
          
          <b-col
            lg="4"
            class="mb-lg-0 mb-2 d-flex justify-content-center justify-content-lg-between align-items-center"
          >
            <b-button variant="primary" class="" @click="addSubCate">
              <span class="text-nowrap"
                ><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button>
          </b-col>
        </b-row>
        <!-- <b-row class="">
        <b-col
          md="12"
          class="d-flex justify-content-center justify-content-md-start align-items-center"
        >
          <div class="d-flex align-items-center justify-content-end">
            
          </div>
        </b-col>
      </b-row> -->
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
import CategoryService from '@/services/CategoryService'
import ProjectsService from '@/services/ProjectsService'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      keywordDesc: '',
      ssomac: null,
      SSOMACarray: [
      {
          text: 'Sí',
          value: true
        },
        {
          text: 'No',
          value: false
        },
      ],
      isAdd: false,
      isUpdate: false,
      see_more: false,
      categoria: '',
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      records: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {
    /* this.getCategory(this.project_id)
    this.getSelect() */
  },
  methods: {
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addSubCate() {
      this.isAdd = true
      this.$refs.subcategoriaAdd.setData({})
    },
    searrchDataByFilter() {

      this.arrayFilters = []

      if (this.keywordDesc != null) {
        this.arrayFilters.push({
          keyContains: 'description',
          key: 'contains',
          value: this.keywordDesc
        })
      }
      if (this.ssomac != null) {
        this.arrayFilters.push({ keyContains: 'is_ssomac', key: 'equals', value: this.ssomac })
      }

      if (this.arrayFilters.length > 0) {
      
        this.$parent.$parent.arrayFilters = this.arrayFilters;
        this.$parent.$parent.getData();
      }
    },
    async getSelect() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
        // if (this.proyectos.length > 0) {
        //   this.project_id = respProyectos.data.rows[0].id
        // } else {
        //   if (this.user_role == 'planner') {
        //     this.project_id = 0
        //   }
        // }
      }
      // this.getCategory(this.project_id)
       this.filter()
    },
    async getCategory(id) {
      this.categoria = ''
      const url =
        `?limit=100&filter=` +
        JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const resp = await CategoryService.getCategorys(url, this.$store)
      console.log('resp', resp)
      if (resp.status) {
        this.records = resp.data.rows
        if (this.records.length > 0) {
          this.categoria =0
        } else {
          if (this.user_role == 'planner') {
            this.categoria = 0
          }
        }
      }
      //this.filter()
    },
    async filter() {
      console.log('filterrr')
      var arrayFilter = []
      if (this.categoria != '' && this.categoria != null) {
        arrayFilter.push({
          keyContains: 'category_id',
          key: 'equals',
          value: this.categoria
        })
      }
      arrayFilter.push({
        keyContains: 'category.deleted_at',
        key: 'equals',
        value: null
        })
        arrayFilter.push({
        keyContains: 'category.project_id',
        key: 'equals',
        value: this.project_id
        })
      if (arrayFilter.length > 0) {
        this.$parent.$parent.arrayFilters = arrayFilter
        this.$parent.$parent.getData()
      }

      console.log('arrayFilter', arrayFilter)
    },
    clean() {
      console.log({ prefilter: this.$parent.$parent.arrayFilters })
      this.categoria = null
      // this.project_id = null
      var arrayFilter = []
      if (this.user_role != 'admin') {
        const cate = []
        if (this.records.length > 0) {
          for (let index = 0; index < this.records.length; index++) {
            const element = this.records[index]
            cate.push(element.id)
          }
          if (cate.length > 0) {
            arrayFilter.push({
              keyContains: 'category_id',
              key: 'in',
              value: JSON.stringify(cate)
            })
          }
        } else {
          arrayFilter.push({
            keyContains: 'category_id',
            key: 'equals',
            value: null
          })
        }
      }
      arrayFilter.push({
        keyContains: 'category.project_id',
        key: 'equals',
        value: this.project_id
      })
      console.log({arrayFilter2:arrayFilter})
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.body_card {
  display: block;
  transition: 0.3s;
}
</style>
